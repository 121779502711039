<div fxLayout="column" fxLayoutGap="20px">
  <h1>Concurso</h1>
  <mat-card class="warn"
    >Evitar que os prazos de inscrições ou recursos terminem em sábado, domingo, feriado ou dia em que não houver
    expediente ou que o expediente for encerrado antes do horário normal.</mat-card
  >

  <mat-card>
    <mat-card-content>
      <fieldset fxFlex="100%">
        <legend>Cadastro</legend>
        <form [formGroup]="form" fxLayout="column" fxLayoutGap="5px">
          <mat-form-field appearance="outline">
            <mat-label>Unidade</mat-label>
            <mat-select formControlName="uuid" name="uuid">
              <mat-option *ngFor="let campus of campusHabilitados" [value]="campus.uuid.toString()">
                {{ campus.nome }}
              </mat-option>
            </mat-select>
            <mat-hint>Escolha a unidade que o concurso é destinado.</mat-hint>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Tipo de concurso</mat-label>
            <mat-select formControlName="tipo" name="tipo">
              <mat-option
                *ngFor="let type of (showTipoConcursoDTA() ? tipoConcursoDTA : tipoConcurso) | keyvalue"
                [value]="type.key">
                {{ type.value }}
              </mat-option>
            </mat-select>
            <mat-hint>Escolha o tipo do concurso.</mat-hint>
          </mat-form-field>

          <app-arquivo-upload
            #upload
            label="Edital"
            [uploadPath]="editalUploadPath"
            accept="application/pdf"
            [fc]="editalUploadFc"
            [usarArquivoViewer]="true"></app-arquivo-upload>

          <mat-form-field appearance="outline">
            <mat-label>Título</mat-label>
            <input matInput formControlName="titulo" name="titulo" maxlength="255" />
            <mat-hint>Informe o título do concurso.</mat-hint>
          </mat-form-field>

          <div fxLayout.gt-md="row" fxLayoutGap="20px" fxFill>
            <mat-form-field appearance="outline" *ngIf="!isTipoDocente">
              <mat-label>Número do despacho</mat-label>
              <input matInput formControlName="numeroDespacho" name="numeroDespacho" maxlength="255" />
              <mat-hint
                >Informe o número do despacho da Reitoria: <b>Número/Ano</b>, (este campo não é visível aos
                candidatos).</mat-hint
              >
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Número do processo</mat-label>
              <input matInput formControlName="processo" name="processo" maxlength="255" />
              <mat-hint
                >Informe o número do processo: <b>Número/Ano/Unidade</b>, (este campo não é visível aos
                candidatos).</mat-hint
              >
            </mat-form-field>

            <mat-form-field appearance="outline" *ngIf="!isTipoDocente">
              <mat-label>Número do CONTAD</mat-label>
              <input matInput formControlName="numeroContad" name="numeroContad" maxlength="255" />
              <mat-hint
                >Informe o número de referência ao <b>Sistema CONTAD</b>, (este campo não é visível aos
                candidatos).</mat-hint
              >
            </mat-form-field>
          </div>

          <mat-form-field appearance="outline">
            <mat-label>Descrição</mat-label>
            <textarea matInput formControlName="descricao" name="descricao"></textarea>
            <mat-hint>Informe a descrição do concurso.</mat-hint>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Destaque</mat-label>
            <textarea matInput formControlName="destaque" name="destaque"></textarea>
            <mat-hint>Opcional, se preenchido será exibido na tela de inscrição na cor vermelha.</mat-hint>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Abstract</mat-label>
            <textarea matInput formControlName="resumo" name="resumo"></textarea>
            <mat-hint>Opcional, Resumo do Edital em outro idioma.</mat-hint>
          </mat-form-field>

          <div fxLayout.gt-md="row" fxLayoutGap="20px" fxFill *ngIf="!isTipoDocente">
            <mat-form-field appearance="outline">
              <mat-label>Vagas</mat-label>
              <input matInput formControlName="vagas" name="vagas" mask="099" />
              <mat-hint>Opcional, informe o número de vagas do concurso.</mat-hint>
            </mat-form-field>

            <mat-form-field appearance="outline" *ngIf="!isTipoDocente">
              <mat-label>Valor Inscrição</mat-label>
              <input
                matInput
                formControlName="valorInscricao"
                name="valorInscricao"
                [dropSpecialCharacters]="false"
                mask="0*,00"
                maxlength="6" />
              <mat-hint>Informe o valor da inscrição do concurso.</mat-hint>
            </mat-form-field>

            <mat-form-field appearance="outline" *ngIf="!isTipoDocente">
              <mat-label>Salário</mat-label>
              <input
                matInput
                formControlName="salario"
                name="salario"
                [dropSpecialCharacters]="false"
                mask="0*,00"
                maxlength="8" />
              <mat-hint>Informe o salário da função.</mat-hint>
            </mat-form-field>
          </div>

          <div fxLayout.gt-md="row" fxLayoutGap="20px" fxFill>
            <mat-form-field appearance="outline">
              <mat-label>Início Inscrição</mat-label>
              <input matInput readonly [matDatepicker]="pickerInicio" formControlName="inicio" name="inicio" />
              <mat-datepicker-toggle matSuffix [for]="pickerInicio"></mat-datepicker-toggle>
              <mat-datepicker #pickerInicio></mat-datepicker>
              <mat-hint>Informe a data de início das inscrições para o concurso.</mat-hint>
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Término Inscrição</mat-label>
              <input matInput readonly [matDatepicker]="pickerFim" formControlName="fim" name="fim" />
              <mat-datepicker-toggle matSuffix [for]="pickerFim"></mat-datepicker-toggle>
              <mat-datepicker #pickerFim></mat-datepicker>
              <mat-hint>Informe a data de término das inscrições para o concurso.</mat-hint>
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Horário de Início da Inscrição</mat-label>
              <input matInput type="time"  formControlName="horaInicio" min="08:00" max="17:00"  />
              <mat-hint>Deve estar dentro do horário de expediente da Unicamp (Das 08:00 às 17:00) para não abrir margem para recursos.</mat-hint>
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Horário de Término da Inscrição</mat-label>
              <input matInput formControlName="horaFim" name="horaFim" [dropSpecialCharacters]="false" mask="Hh:m0" />
              <mat-hint>Deve estar dentro do horário de expediente da Unicamp (Das 08:00 às 17:00) para não abrir margem para recursos.</mat-hint>
            </mat-form-field>
          </div>

          <div fxLayout.gt-md="row" fxLayoutGap="20px" fxFill>
            <mat-form-field appearance="outline">
              <mat-label>Início Inserção dos Documentos da Segunda Fase</mat-label>
              <mat-icon class="danger" matPrefix (click)="clearDateInicioFase2()">close</mat-icon>
              <input
                matInput
                readonly
                [matDatepicker]="pickerInicioFase2"
                formControlName="inicioFase2"
                name="inicioFase2" />
              <mat-datepicker-toggle matSuffix [for]="pickerInicioFase2"></mat-datepicker-toggle>
              <mat-datepicker #pickerInicioFase2></mat-datepicker>
              <mat-hint
                >Informe a data de início do período que o candidato pode complementar sua inscrição com os documentos
                da segunda fase.</mat-hint
              >
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Término Inserção dos Documentos da Segunda Fase</mat-label>
              <mat-icon class="danger" matPrefix (click)="clearDateFimFase2()">close</mat-icon>
              <input matInput readonly [matDatepicker]="pickerFimFase2" formControlName="fimFase2" name="fimFase2" />
              <mat-datepicker-toggle matSuffix [for]="pickerFimFase2"></mat-datepicker-toggle>
              <mat-datepicker #pickerFimFase2></mat-datepicker>
              <mat-hint
                >Informe a data de término do período que o candidato pode complementar sua inscrição com os documentos
                da segunda fase.</mat-hint
              >
            </mat-form-field>
          </div>

          <div fxLayout.gt-md="row" fxLayoutGap="20px" fxFill>
            <mat-form-field appearance="outline">
              <mat-label>Online</mat-label>
              <mat-select name="online" formControlName="online">
                <mat-option value="true">Sim</mat-option>
                <mat-option value="false">Não</mat-option>
              </mat-select>
              <mat-hint
                >Escolha <b>Não</b> para o candidato não poder efetuar sua inscrição online, ele poderá apenas
                visualizar as informações do concurso.</mat-hint
              >
            </mat-form-field>

            <mat-form-field appearance="outline" *ngIf="!isTipoDocente">
              <mat-label>Habilitar Devolução da Taxa de Inscrição</mat-label>
              <mat-select name="habilitarDevolucao" formControlName="habilitarDevolucao">
                <mat-option value="true">Sim</mat-option>
                <mat-option value="false">Não</mat-option>
              </mat-select>
              <mat-hint
                >Escolha <b>Sim</b> para que os candidatos que efetuaram o pagamento possam solicitar a devolução da
                taxa de inscrição.</mat-hint
              >
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Ativo</mat-label>
              <mat-select name="ativo" formControlName="ativo">
                <mat-option value="true">Sim</mat-option>
                <mat-option value="false">Não</mat-option>
              </mat-select>
              <mat-hint
                >Escolha <b>Não</b> e este concurso não será exibido na tela de Inscrições aberta/encerrada.</mat-hint
              >
            </mat-form-field>
          </div>

          <div fxLayout.gt-md="row" fxLayoutGap="20px" fxFill>
            <mat-form-field appearance="outline">
              <mat-label>Email de contato</mat-label>
              <input
                matInput
                formControlName="contatoEmail"
                name="contatoEmail"
                type="email"
                [email]="true"
                maxlength="255" />
              <mat-hint
                >Informe o email que o candidato poderá entrar em contato em caso de dúvida. Este email também receberá
                as notificações de inscrições e recursos.</mat-hint
              >
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Telefone de contato</mat-label>
              <input matInput formControlName="contatoTelefone" name="contatoTelefone" maxlength="255" />
              <mat-hint
                >Informe o número de telefone que o candidato poderá entrar em contato em caso de dúvida (texto livre,
                coloque um ou mais telefones).</mat-hint
              >
            </mat-form-field>
          </div>
          <div fxLayout.gt-md="row" fxLayoutGap="20px" fxFill>
            <mat-form-field appearance="outline">
              <mat-label>Email do Departamento</mat-label>
              <input
                matInput
                formControlName="emailDepartamento"
                name="emailDepartamento"
                type="email"
                [email]="true"
                maxlength="50" />
              <mat-hint>Informe o email do departamento responsável</mat-hint>
            </mat-form-field>
          </div>

          <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>Responsáveis pelos recursos</mat-label>
            <mat-chip-grid #responsaveisChipList>
              <mat-chip-option *ngFor="let responsavel of responsaveis" (removed)="removeChip(responsavel)">
                {{ responsavel }}
                <button matChipRemove>
                  <mat-icon>cancel</mat-icon>
                </button>
              </mat-chip-option>
              <input
                placeholder="identificacao@unicamp.br"
                [matChipInputFor]="responsaveisChipList"
                [matChipInputSeparatorKeyCodes]="chipsSeparatorKeys"
                [matChipInputAddOnBlur]="true"
                (matChipInputTokenEnd)="addChip($event)"
                formControlName="responsaveisRecurso"
                name="responsaveisRecurso" />
            </mat-chip-grid>
            <mat-error *ngIf="responsaveisChipList.errorState && responsaveisRecurso.invalid">
              Somente <strong>emails</strong> @unicamp.br.
            </mat-error>
            <mat-hint>Email dos responsáveis.</mat-hint>
          </mat-form-field>
        </form>
        <mat-divider></mat-divider>
        <div *ngIf="hasPontos" fxLayout="column" fxLayoutGap="20px">
          <h3 class="tituloPontos">Pontos</h3>
          <div fxLayout.gt-md="row" fxLayoutGap="20px" fxLayoutAlign="space-between center">
            <mat-form-field appearance="outline">
              <mat-label>Titulo</mat-label>
              <input matInput [formControl]="pontoInput" (blur)="pontoInputOnBlur()" />
            </mat-form-field>
            <ng-container *ngIf="editarPontoIdx === -1; then first; else second"></ng-container>
            <ng-template #first>
              <div>
                <button mat-icon-button (click)="adicionarPonto()" color="primary">
                  <mat-icon>add</mat-icon>
                </button>
              </div>
            </ng-template>
            <ng-template #second>
              <div fxLayout.gt-md="row">
                <button mat-icon-button (click)="atualizarPonto()" color="primary" title="Alterar">
                  <mat-icon>edit</mat-icon>
                </button>
                &nbsp;
                <button mat-icon-button (click)="limparPontoInput()" color="warn" title="Cancelar">
                  <mat-icon>cancel</mat-icon>
                </button>
              </div>
            </ng-template>
          </div>
          <table mat-table [dataSource]="pontosDataSource">
            <ng-container matColumnDef="data">
              <th mat-header-cell *matHeaderCellDef>Data</th>
              <td mat-cell *matCellDef="let row">
                {{ row.data | date : 'dd/MM/yyyy HH:mm:ss' }}
              </td>
            </ng-container>
            <ng-container matColumnDef="titulo">
              <th mat-header-cell *matHeaderCellDef>Titulo</th>
              <td mat-cell *matCellDef="let row">
                <p>{{ row.titulo }}</p>
              </td>
            </ng-container>
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let row; let i = index">
                <button mat-icon-button (click)="editarPonto(row, i)" title="Alterar"><mat-icon>edit</mat-icon></button>
                &nbsp;
                <button mat-icon-button color="warn" (click)="excluirPonto(row, i)" title="Excluir">
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="colunasPontoGrid"></tr>
            <tr
              mat-row
              *matRowDef="let row; let i = index; columns: colunasPontoGrid"
              [ngClass]="{ editandoPonto: editarPontoIdx === i }"></tr>
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="5">
                <span>Nenhum Ponto</span>
              </td>
            </tr>
          </table>
        </div>
      </fieldset>
    </mat-card-content>
    <mat-card-actions>
      <div
        fxLayoutAlign.gt-md="space-between center"
        fxLayoutAlign.lt-md="center stretch"
        fxLayout.lt-md="column"
        fxLayoutGap="20px">
        <button mat-raised-button color="primary" (click)="processarFormulario()" *ngIf="this.id === undefined">
          <mat-icon>add</mat-icon> Adicionar
        </button>
        <button
          mat-raised-button
          color="primary"
          (click)="processarFormulario()"
          *ngIf="this.id !== undefined"
          [disabled]="!alteracoesNosPontos && form.pristine && editalUploadFc.pristine">
          <mat-icon>edit</mat-icon> Atualizar
        </button>
        <button mat-stroked-button color="warn" routerLink="/concurso"><mat-icon>close</mat-icon> Cancelar</button>
      </div>
    </mat-card-actions>
  </mat-card>
</div>
