/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import {
  UnespCoreAccessDeniedComponent,
  UnespCoreHomeComponent,
  UnespCorePageNotFoundComponent,
  UnespCoreAuthGuardService,
} from 'src/libs/unesp-core'
import { environment } from 'src/environments/environment'

import { ListaConcursoComponent } from './modules/concurso/lista-concurso/lista-concurso.component'
import { CadastroConcursoComponent } from './modules/concurso/cadastro-concurso/cadastro-concurso.component'
import { CadastroAndamentoComponent } from './modules/concurso/cadastro-andamento/cadastro-andamento.component'
import { ListaUsuarioExternoComponent } from './modules/usuario-externo/lista-usuario-externo/lista-usuario-externo.component'
import { CadastroUsuarioExternoComponent } from './modules/usuario-externo/cadastro-usuario-externo/cadastro-usuario-externo.component'
import { ListaInscricaoComponent } from './modules/inscricao/lista-inscricao/lista-inscricao.component'
import { InscricaoFichaComponent } from './modules/inscricao/inscricao-ficha/inscricao-ficha.component'
import { InscricaoPagamentoComponent } from './modules/inscricao/inscricao-pagamento/inscricao-pagamento.component'
import { InscricaoReducaoComponent } from './modules/inscricao/inscricao-reducao/inscricao-reducao.component'
import { InscricaoSituacaoComponent } from './modules/inscricao/inscricao-situacao/inscricao-situacao.component'
import { ListaUsuarioCandidatoComponent } from './modules/usuario-candidato/lista-usuario-candidato/lista-usuario-candidato.component'
import { UsuarioCandidatoEmailComponent } from './modules/usuario-candidato/usuario-candidato-email/usuario-candidato-email.component'
import { UsuarioCandidatoSenhaComponent } from './modules/usuario-candidato/usuario-candidato-senha/usuario-candidato-senha.component'
import { ListaRecursoComponent } from './modules/recurso/lista-recurso/lista-recurso.component'
import { RecursoAnaliseComponent } from './modules/recurso/recurso-analise/recurso-analise.component'
import { ListaConfiguracaoComponent } from './modules/configuracao/lista-configuracao/lista-configuracao.component'
import { ConfiguracaoEditarComponent } from './modules/configuracao/configuracao-editar/configuracao-editar.component'
import { PermitirAlteracaoComponent } from './modules/permitir-alteracao/permitir-alteracao.component'
import { PermitirAlterarAndamentoComponent } from './modules/permitir-alteracao/permitir-alterar-andamento/permitir-alterar-andamento.component'
import { ConsultaAndamentoComponent } from './modules/consulta-andamento/consulta-andamento.component'
import { PendingChangesGuard } from './utils/pending-changes'
import { ListaFormularioComplementarComponent } from './modules/formulario-complementar/lista-formulario-complementar/lista-formulario-complementar.component'
import { CadastroFormularioComplementarComponent } from './modules/formulario-complementar/cadastro-formulario-complementar/cadastro-formulario-complementar.component'
import { ListaPerfilComponent } from './modules/perfis/lista-perfil/lista-perfil.component'
import { PerfilPorUsuarioComponent } from './modules/perfis/perfil-por-usuario/perfil-por-usuario.component'
import { AjudaSuporteComponent } from './modules/ajuda/suporte/ajuda-suporte.component'
import { ConsultaGerencialComponent } from './modules/consulta-gerencial/consulta-gerencial.component'
import { ConsultaLogComponent } from './modules/consulta-log/consulta-log.component'
import { ReducaoTaxaComponent } from './modules/listagens/reducao-taxa/reducao-taxa.component'
import { PagamentoComponent } from './modules/listagens/pagamento/pagamento.component'
import { SituacaoInscricaoComponent } from './modules/listagens/situacao-inscricao/situacao-inscricao.component'
import { ClassificacaoInscricaoComponent } from './modules/listagens/classificacao-inscricao/classificacao-inscricao.component'
import { ListaInscricoesDocumentoComponent } from './modules/listagens/lista-inscricoes-documento/lista-inscricoes-documento.component'
import { ManualComponent } from './modules/manual/manual/manual.component'
import { CentralAcessoHomeComponent } from './modules/central-acesso/home/central-acesso-home.component'
import { ProvasGerenciaComponent } from './modules/concurso/provas/provas-gerencia/provas-gerencia.component'
import { ProvaCriteriosComponent } from './modules/concurso/provas/prova-criterios/prova-criterios.component'
import { ListarNotasCandidatosComponent } from './modules/concurso/provas/prova-notas/listar-notas-candidatos/listar-notas-candidatos.component'
import { HomeComponent } from './modules/home/home.component'
import { TipoRecursoListarComponent } from './modules/tipo-recurso/listar/tipo-recurso-listar.component'
import { TipoRecursoEditarComponent } from './modules/tipo-recurso/editar/tipo-recurso-editar.component'
import { TipoRecursoIncluirComponent } from './modules/tipo-recurso/incluir/tipo-recurso-incluir/tipo-recurso-incluir.component'
import { UsuarioCandidatoVincularContasComponent } from './modules/usuario-candidato/usuario-candidato-vincular-contas/usuario-candidato-vincular-contas.component'
import { StatusDocumentacaoComponent } from './modules/concurso/status-documentacao/status-documentacao.component'
import { AuthComponent } from './modules/auth/auth.component'
import { LogoutComponent } from './modules/logout/logout.component'
const routes: Routes = [
  {
    path: '',
    //component: HomeComponent,
     component: AuthComponent
  },
  {
    path: 'access-denied',
    component: UnespCoreAccessDeniedComponent,
  },
  {
    path: 'auth',
    component: AuthComponent,
  },
  {
    path: 'logout',
    component: LogoutComponent,
  },
  {
    path: 'page-not-found',
    component: UnespCorePageNotFoundComponent,
  },
  {
    path: 'ajuda/suporte',
    component: AjudaSuporteComponent,
  },
  {
    path: 'concurso',
    component: ListaConcursoComponent,
    canActivate: [UnespCoreAuthGuardService],
    data: {
      allowedRoles: [
        `${environment.roles.RH.name}`,
        `${environment.roles.DTA.name}`,
        `${environment.roles.CCPT.name}`,
        `${environment.roles.CACP.name}`,
        `${environment.roles.FINANCAS.name}`,
        `${environment.roles.DIRETOR.name}`,
        `${environment.roles.MULTIUNIDADES.name}`,
        `${environment.roles.DEPARTAMENTO.name}`,
      ],
    },
  },
  {
    path: 'concurso/cadastro',
    component: CadastroConcursoComponent,
    canActivate: [UnespCoreAuthGuardService],
    data: {
      allowedRoles: [`${environment.roles.RH.name}`, `${environment.roles.DTA.name}`],
    },
  },
  {
    path: 'concurso/cadastro/:id',
    component: CadastroConcursoComponent,
    canActivate: [UnespCoreAuthGuardService],
    canDeactivate: [PendingChangesGuard],
    data: {
      allowedRoles: [`${environment.roles.RH.name}`, `${environment.roles.DTA.name}`],
    },
  },
  {
    path: 'concurso/:idConcurso/andamento',
    component: CadastroAndamentoComponent,
    canActivate: [UnespCoreAuthGuardService],
    data: {
      allowedRoles: [`${environment.roles.RH.name}`, `${environment.roles.DTA.name}`],
    },
  },
  {
    path: 'concurso/:idConcurso/provas',
    component: ProvasGerenciaComponent,
    canActivate: [UnespCoreAuthGuardService],
    data: {
      allowedRoles: [`${environment.roles.RH.name}`, `${environment.roles.DTA.name}`],
      //allowedRoles: []
    },
  },
  {
    path: 'concurso/:idConcurso/prova/:tipoProva/criterios',
    component: ProvaCriteriosComponent,
    canActivate: [UnespCoreAuthGuardService],
    data: {
      allowedRoles: [`${environment.roles.RH.name}`, `${environment.roles.DTA.name}`],
    },
  },
  {
    path: 'concurso/:idConcurso/prova/:tipoProva/notas',
    component: ListarNotasCandidatosComponent,
    canActivate: [UnespCoreAuthGuardService],
    canDeactivate: [PendingChangesGuard],
    data: {
      allowedRoles: [`${environment.roles.RH.name}`, `${environment.roles.DTA.name}`],
    },
  },
  {
    path: 'listagem-documentos/:idConcurso',
    component: ListaInscricoesDocumentoComponent,
    canActivate: [UnespCoreAuthGuardService],
    data: {
      allowedRoles: [`${environment.roles.RH.name}`, `${environment.roles.DTA.name}`],
    },
  },
  {
    path: 'listagem-reducao-taxa/:id',
    component: ReducaoTaxaComponent,
    canActivate: [UnespCoreAuthGuardService],
    data: {
      allowedRoles: [
        `${environment.roles.RH.name}`,
        `${environment.roles.DTA.name}`,
        `${environment.roles.FINANCAS.name}`,
      ],
    },
  },
  {
    path: 'listagem-situacao-inscricao/:id',
    component: SituacaoInscricaoComponent,
    canActivate: [UnespCoreAuthGuardService],
    data: {
      allowedRoles: [`${environment.roles.RH.name}`, `${environment.roles.DTA.name}`],
    },
  },
  {
    path: 'listagem/situacao-inscricao/:id/:id/concluir',
    component: SituacaoInscricaoComponent,
    canActivate: [UnespCoreAuthGuardService],
    data: {
      allowedRoles: [`${environment.roles.RH.name}`, `${environment.roles.DTA.name}`],
    },
  },
  {
    path: 'listagem/situacao-inscricao/:id/:id/salvar',
    component: SituacaoInscricaoComponent,
    canActivate: [UnespCoreAuthGuardService],
    data: {
      allowedRoles: [`${environment.roles.RH.name}`, `${environment.roles.DTA.name}`],
    },
  },
  {
    path: 'listagem-classificacao-inscricao/:id',
    component: ClassificacaoInscricaoComponent,
    canActivate: [UnespCoreAuthGuardService],
    data: {
      allowedRoles: [`${environment.roles.RH.name}`, `${environment.roles.DTA.name}`],
    },
  },
  {
    path: 'listagem-status-documentacao/:id',
    component: StatusDocumentacaoComponent,
    canActivate: [UnespCoreAuthGuardService],
    data: {
      allowedRoles: [`${environment.roles.RH.name}`, `${environment.roles.DTA.name}`],
    },
  },
  {
    path: 'listagem-pagamento/:id',
    component: PagamentoComponent,
    canActivate: [UnespCoreAuthGuardService],
    data: {
      allowedRoles: [
        `${environment.roles.RH.name}`,
        `${environment.roles.DTA.name}`,
        `${environment.roles.FINANCAS.name}`,
      ],
    },
  },

  {
    path: 'usuario-externo',
    component: ListaUsuarioExternoComponent,
    canActivate: [UnespCoreAuthGuardService],
    data: { allowedRoles: [`${environment.roles.RH.name}`, `${environment.roles.DTA.name}`] },
  },
  {
    path: 'usuario-externo/cadastro',
    component: CadastroUsuarioExternoComponent,
    canActivate: [UnespCoreAuthGuardService],
    data: { allowedRoles: [`${environment.roles.RH.name}`, `${environment.roles.DTA.name}`] },
  },
  {
    path: 'usuario-externo/cadastro/:cpf',
    component: CadastroUsuarioExternoComponent,
    canActivate: [UnespCoreAuthGuardService],
    data: {
      allowedRoles: [`${environment.roles.RH.name}`, `${environment.roles.DTA.name}`],
    },
  },
  {
    path: 'inscricao',
    component: ListaInscricaoComponent,
    canActivate: [UnespCoreAuthGuardService],
    data: {
      allowedRoles: [
        `${environment.roles.RH.name}`,
        `${environment.roles.DTA.name}`,
        `${environment.roles.FINANCAS.name}`,
        `${environment.roles.CCPT.name}`,
        `${environment.roles.CACP.name}`,
        `${environment.roles.DEPARTAMENTO.name}`,
        `${environment.roles.MULTIUNIDADES.name}`,
        `${environment.roles.DIRETOR.name}`,
      ],
    },
  },
  {
    path: 'inscricao/:idConcurso',
    component: ListaInscricaoComponent,
    canActivate: [UnespCoreAuthGuardService],
    data: {
      comIdentificacao: 'comIdentificacao',
      allowedRoles: [
        `${environment.roles.RH.name}`,
        `${environment.roles.DTA.name}`,
        `${environment.roles.FINANCAS.name}`,
        `${environment.roles.CCPT.name}`,
        `${environment.roles.CACP.name}`,
        `${environment.roles.DEPARTAMENTO.name}`,
        `${environment.roles.MULTIUNIDADES.name}`,
        `${environment.roles.DIRETOR.name}`,
      ],
    },
  },
  {
    path: 'inscricao/:idConcurso/pago/:pago',
    component: ListaInscricaoComponent,
    canActivate: [UnespCoreAuthGuardService],
    data: {
      allowedRoles: [
        `${environment.roles.RH.name}`,
        `${environment.roles.DTA.name}`,
        `${environment.roles.FINANCAS.name}`,
        `${environment.roles.CCPT.name}`,
        `${environment.roles.CACP.name}`,
        `${environment.roles.DEPARTAMENTO.name}`,
      ],
    },
  },
  {
    path: 'inscricao/ficha/:id',
    pathMatch: 'full',
    component: InscricaoFichaComponent,
    canActivate: [UnespCoreAuthGuardService],
    data: {
      allowedRoles: [
        `${environment.roles.RH.name}`,
        `${environment.roles.DTA.name}`,
        `${environment.roles.FINANCAS.name}`,
        `${environment.roles.CCPT.name}`,
        `${environment.roles.CACP.name}`,
        `${environment.roles.DEPARTAMENTO.name}`,
        `${environment.roles.MULTIUNIDADES.name}`,
        `${environment.roles.DIRETOR.name}`,
      ],
    },
  },
  {
    path: 'inscricao/ficha/:id/:concursoId',
    pathMatch: 'full',
    component: InscricaoFichaComponent,
    canActivate: [UnespCoreAuthGuardService],
    data: {
      allowedRoles: [
        `${environment.roles.RH.name}`,
        `${environment.roles.DTA.name}`,
        `${environment.roles.FINANCAS.name}`,
        `${environment.roles.CCPT.name}`,
        `${environment.roles.CACP.name}`,
        `${environment.roles.DEPARTAMENTO.name}`,
        `${environment.roles.MULTIUNIDADES.name}`,
        `${environment.roles.DIRETOR.name}`,
      ],
    },
  },
  {
    path: 'usuario-candidato',
    component: ListaUsuarioCandidatoComponent,
    canActivate: [UnespCoreAuthGuardService],
    data: {
      allowedRoles: [`${environment.roles.GERENTE.name}`],
    },
  },
  {
    path: 'usuario-candidato/:id/email',
    component: UsuarioCandidatoEmailComponent,
    canActivate: [UnespCoreAuthGuardService],
    data: {
      allowedRoles: [`${environment.roles.GERENTE.name}`],
    },
  },
  {
    path: 'usuario-candidato/:id/senha',
    component: UsuarioCandidatoSenhaComponent,
    canActivate: [UnespCoreAuthGuardService],
    data: {
      allowedRoles: [`${environment.roles.GERENTE.name}`],
    },
  },
  {
    path: 'usuario-candidato/:id/vincular-emails',
    component: UsuarioCandidatoVincularContasComponent,
    canActivate: [UnespCoreAuthGuardService],
    data: {
      allowedRoles: [`${environment.roles.GERENTE.name}`],
    },
  },
  {
    path: 'usuario-candidato/lista-inscricoes-email/',
    component: UsuarioCandidatoVincularContasComponent,
    canActivate: [UnespCoreAuthGuardService],
    data: {
      allowedRoles: [`${environment.roles.GERENTE.name}`],
    },
  },
  {
    path: 'recurso',
    component: ListaRecursoComponent,
    canActivate: [UnespCoreAuthGuardService],
    data: {
      allowedRoles: [
        `${environment.roles.RH.name}`,
        `${environment.roles.DEPARTAMENTO.name}`,
        `${environment.roles.DIRETOR.name}`,
      ],
    },
  },
  {
    path: 'recurso/analise/:id',
    component: RecursoAnaliseComponent,
    canActivate: [UnespCoreAuthGuardService],
    data: {
      allowedRoles: [
        `${environment.roles.RH.name}`,
        `${environment.roles.DEPARTAMENTO.name}`,
        `${environment.roles.DIRETOR.name}`,
      ],
    },
  },
  {
    path: 'configuracao',
    component: ListaConfiguracaoComponent,
    canActivate: [UnespCoreAuthGuardService],
    data: {
      allowedRoles: [`${environment.roles.RH.name}`, `${environment.roles.FINANCAS.name}`],
    },
  },
  {
    path: 'configuracao/editar/:id',
    component: ConfiguracaoEditarComponent,
    canActivate: [UnespCoreAuthGuardService],
    data: {
      allowedRoles: [`${environment.roles.RH.name}`, `${environment.roles.FINANCAS.name}`],
    },
  },
  {
    path: 'gerencia-configuracao',
    component: ListaConfiguracaoComponent,
    canActivate: [UnespCoreAuthGuardService],
    data: {
      allowedRoles: [`${environment.roles.GERENTE.name}`],
    },
  },
  {
    path: 'gerencia-configuracao/editar/:id',
    component: ConfiguracaoEditarComponent,
    canActivate: [UnespCoreAuthGuardService],
    data: {
      allowedRoles: [`${environment.roles.GERENTE.name}`],
    },
  },
  {
    path: 'tipo-recurso',
    component: TipoRecursoListarComponent,
    canActivate: [UnespCoreAuthGuardService],
    data: {
      allowedRoles: [`${environment.roles.GERENTE.name}`],
    },
  },
  {
    path: 'tipo-recurso/incluir',
    component: TipoRecursoIncluirComponent,
    canActivate: [UnespCoreAuthGuardService],
    data: {
      allowedRoles: [`${environment.roles.GERENTE.name}`],
    },
  },
  {
    path: 'tipo-recurso/editar/:id',
    component: TipoRecursoEditarComponent,
    canActivate: [UnespCoreAuthGuardService],
    data: {
      allowedRoles: [`${environment.roles.GERENTE.name}`],
    },
  },

  {
    path: 'permitir-alteracao',
    component: PermitirAlteracaoComponent,
    canActivate: [UnespCoreAuthGuardService],
    data: {
      allowedRoles: [`${environment.roles.GERENTE.name}`],
    },
  },
  {
    path: 'permitir-alteracao/pagamento/:id',
    component: InscricaoPagamentoComponent,
    canActivate: [UnespCoreAuthGuardService],
    data: {
      allowedRoles: [`${environment.roles.GERENTE.name}`],
    },
  },
  {
    path: 'permitir-alteracao/reducao/:id',
    component: InscricaoReducaoComponent,
    canActivate: [UnespCoreAuthGuardService],
    data: {
      allowedRoles: [`${environment.roles.GERENTE.name}`],
    },
  },
  {
    path: 'permitir-alteracao/deferimento/:id',
    component: InscricaoSituacaoComponent,
    canActivate: [UnespCoreAuthGuardService],
    data: {
      allowedRoles: [`${environment.roles.GERENTE.name}`],
    },
  },
  {
    path: 'permitir-alteracao/recurso/:id',
    component: RecursoAnaliseComponent,
    canActivate: [UnespCoreAuthGuardService],
    data: {
      allowedRoles: [`${environment.roles.GERENTE.name}`],
    },
  },
  {
    path: 'permitir-alteracao/andamento/:id',
    component: PermitirAlterarAndamentoComponent,
    canActivate: [UnespCoreAuthGuardService],
    data: {
      allowedRoles: [`${environment.roles.GERENTE.name}`],
    },
  },
  {
    path: 'consulta-andamento',
    component: ConsultaAndamentoComponent,
    canActivate: [UnespCoreAuthGuardService],
    data: {
      allowedRoles: [`${environment.roles.GERENTE.name}`],
    },
  },
  {
    path: 'consulta-gerencial',
    component: ConsultaGerencialComponent,
    canActivate: [UnespCoreAuthGuardService],
    data: {
      allowedRoles: [`${environment.roles.GERENTE.name}`],
    },
  },
  {
    path: 'consulta-log',
    component: ConsultaLogComponent,
    canActivate: [UnespCoreAuthGuardService],
    data: {
      allowedRoles: [`${environment.roles.GERENTE.name}`],
    },
  },
  {
    path: 'formulario-complementar',
    component: ListaFormularioComplementarComponent,
    canActivate: [UnespCoreAuthGuardService],
    data: {
      allowedRoles: [`${environment.roles.GERENTE.name}`],
    },
  },
  {
    path: 'formulario-complementar/adicionar',
    component: CadastroFormularioComplementarComponent,
    canActivate: [UnespCoreAuthGuardService],
    data: {
      allowedRoles: [`${environment.roles.GERENTE.name}`],
    },
  },
  {
    path: 'formulario-complementar/adicionar/:modelo',
    component: CadastroFormularioComplementarComponent,
    canActivate: [UnespCoreAuthGuardService],
    data: {
      allowedRoles: [`${environment.roles.GERENTE.name}`],
    },
  },
  {
    path: 'formulario-complementar/editar/:id',
    component: CadastroFormularioComplementarComponent,
    canActivate: [UnespCoreAuthGuardService],
    data: {
      allowedRoles: [`${environment.roles.GERENTE.name}`],
    },
  },
  {
    path: 'perfil',
    component: ListaPerfilComponent,
    canActivate: [UnespCoreAuthGuardService],
    data: {
      allowedRoles: [`${environment.roles.GERENTE.name}`],
    },
  },
  {
    path: 'perfil/:identificacao',
    component: PerfilPorUsuarioComponent,
    canActivate: [UnespCoreAuthGuardService],
    data: {
      allowedRoles: [`${environment.roles.GERENTE.name}`],
    },
  },
  {
    path: 'manual/stgp',
    component: ManualComponent,
    canActivate: [UnespCoreAuthGuardService],
    data: {
      perfil: 'STGP',
      allowedRoles: [`${environment.roles.RH.name}`],
    },
  },
  {
    path: 'manual/dta',
    component: ManualComponent,
    canActivate: [UnespCoreAuthGuardService],
    data: {
      perfil: 'DTA',
      allowedRoles: [`${environment.roles.DTA.name}`],
    },
  },
  {
    path: 'manual/financas',
    component: ManualComponent,
    canActivate: [UnespCoreAuthGuardService],
    data: {
      perfil: 'Finanças',
      allowedRoles: [`${environment.roles.FINANCAS.name}`],
    },
  },
  {
    path: 'manual/banca',
    component: ManualComponent,
    canActivate: [UnespCoreAuthGuardService],
    data: {
      perfil: 'Banca',
      allowedRoles: [`${environment.roles.DEPARTAMENTO.name}`],
    },
  },
  {
    path: 'manual/ccpt',
    component: ManualComponent,
    canActivate: [UnespCoreAuthGuardService],
    data: {
      perfil: 'CCPT',
      allowedRoles: [`${environment.roles.CCPT.name}`],
    },
  },
  {
    path: 'manual/cacp',
    component: ManualComponent,
    canActivate: [UnespCoreAuthGuardService],
    data: {
      perfil: 'CACP',
      allowedRoles: [`${environment.roles.CACP.name}`],
    },
  },
  {
    path: 'central-acesso/home',
    component: CentralAcessoHomeComponent,
  },
  {
    path: '**',
    redirectTo: '/page-not-found',
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
